<template>
  <div id="blob"></div>
  <div id="blur"></div>
  <nav>
    <div id="nav-left">
      <a href="https://www.linkedin.com/in/jakobschlosser/" target="_blank" rel="noopener noreferrer"><img class="nav-icon" src="@/assets/linkedin.png" alt="linkedin"/></a>
      <a href="mailto:jakob.schlosser97@gmail.com"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail nav-icon"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg></a>
    </div>
    <div id="nav-right">
      <router-link to="/" exact-active-class="active-link">about me</router-link>
      <router-link to="/projects" exact-active-class="active-link">projects</router-link>
    </div>
  </nav>
  <div class="content">
    <router-view/>
  </div>
  <div class="footer">
    <router-link to="/impressum">Imprint</router-link>
    <router-link to="/attributions">Attributions</router-link>
  </div>
</template>